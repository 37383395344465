import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "56px",
    width: "56px",
    borderRadius: "100%",
    border: "solid",
    borderWidth: "2px",
    borderColor: "#222",
    transition: "all 300ms",
    "&:hover": {
      borderColor: "#333",
      backgroundColor: "#0004",
    },
    [theme.breakpoints.down("sm")]: {},
  },
}))

export const MenuIcon = () => {
  const classes = useStyles()
  return (
    <div className={classes.container} id="cont">
      <svg
        id="svg"
        width="56"
        height="56"
        viewport="0 0 56 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="#F26522" x="12" y="19" width="28" height="2"></rect>
        <rect fill="#F26522" x="12" y="27" width="28" height="2"></rect>
        <rect fill="#F26522" x="12" y="35" width="28" height="2"></rect>
        <circle
          className={classes.bar}
          id="bar"
          r="40"
          cx="44"
          cy="44"
          fill="transparent"
          strokeDasharray="125.66"
        ></circle>
      </svg>
    </div>
  )
}
