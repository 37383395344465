import { Box, Typography } from "@material-ui/core"
import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link } from "react-scroll"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  activeStyle: {
    "& #link": { color: "#fff !important" },
  },
  linkStyle: {
    cursor: "pointer",
    textDecoration: "none",
    width: "100%",
    display: "flex",
    height: "64px",
    alignItems: "center",
  },
  textStyle: {
    fontSize: "22px",
    fontWeight: 400,
    textTransform: "uppercase",
    color: "#aaa",
    "&:hover": {
      color: "#fff",
    },
  },
}))

export const MenuLink = ({ title, link, children, onClick, ...props }) => {
  const classes = useStyles()

  return !onClick ? (
    <GatsbyLink
      to={link}
      activeClassName={classes.activeStyle}
      className={classes.linkStyle}
      {...props}
    >
      <Box mx={3} my={1}>
        <Typography id="link" variant="h3" className={classes.textStyle}>
          {title}
        </Typography>
      </Box>
    </GatsbyLink>
  ) : (
    <Link
      onClick={onClick}
      activeClassName={classes.activeStyle}
      className={classes.linkStyle}
      {...props}
    >
      <Box mx={3} my={1}>
        <Typography variant="h3" className={classes.textStyle}>
          {title}
        </Typography>
      </Box>
    </Link>
  )
}
