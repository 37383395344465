import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-scroll"

const ScrollTo = ({ to, children, offset = -50, onClick, className }) => (
  <Link
    // activeClass="active"
    to={to}
    spy
    smooth
    offset={offset}
    duration={700}
    onClick={onClick}
    className={className}
  >
    {children}
  </Link>
)

ScrollTo.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  offset: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

ScrollTo.defaultProps = {
  offset: -50,
  onClick: () => {},
  className: "",
}

export default ScrollTo
