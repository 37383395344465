import { Box, makeStyles } from "@material-ui/core"
import React, { useEffect, useRef } from "react"
import { DigicLink } from "../digic-link"
import { MenuIcon } from "../layout/menu-icon"
import { Link } from "gatsby"
import { NotContainWords } from "../utils/NotContainWords"
import { useLocation } from "@reach/router"

const productPages = [
  { name: "Home", link: "/" },
  { name: "Pictures", link: "/pictures" },
  { name: "Services", link: "/services" },
  { name: "Studios", link: "/studios" },
  { name: "Productions", link: "/productions" },
]

const useStyles = makeStyles(theme => ({
  container: {
    position: "fixed",
    width: "100%",
    zIndex: 999,
  },
  menubar: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      height: "70px",
    },
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    alignItems: "center",
    height: "100px",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "66px",
    height: "66px",
    cursor: "pointer",
  },
  logoSubpage: {
    width: "120px",
    margin: "10px 0",
    filter: "drop-shadow(2px 2px 4px #0009)",
    [theme.breakpoints.down("sm")]: {
      width: "75px",
    },
  },
  logo: {
    width: "100px",
    margin: "4px 0",
    [theme.breakpoints.down("sm")]: {
      width: "75px",
    },
  },
  topLink: {
    margin: "8px 16px",
    [theme.breakpoints.down("xs")]: {
      margin: "4px",
    },
  },
  topLinkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#000000",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      padding: "0 16px",
    },
  },
  desktopNavbar: {
    padding: "8px 24px",
    background: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    backdropFilter: "blur(10px)",
    justifyContent: "space-between",
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    alignItems: "center",
  },
  mobileNavbar: {
    background: "rgba(0, 0, 0, 0.75)",
    marginTop: "-1px",
    backdropFilter: "blur(10px)",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  link: {
    fontFamily: "Geogrotesque",
    fontWeight: "400",
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#CCC",
    "&:hover": {
      color: "#FFF",
    },
  },
  activeLink: {
    fontFamily: "Geogrotesque",
    fontWeight: "400",
    textTransform: "uppercase",
    textDecoration: "none",

    "&:hover": {
      color: "#FFF",
    },
    color: theme.palette.primary.main,
  },
}))

export const Navbar = ({ setMenuOpen, logo, to }) => {
  const classes = useStyles()
  const menubarRef = useRef()
  const logoRef = useRef()
  const productsRef = useRef()
  const location = useLocation()
  const currentPathname = location.pathname

  useEffect(() => {
    if (window) {
      const callBack = () => {
        if (menubarRef.current) {
          menubarRef.current.style.backgroundColor = `rgba(1, 1, 1, ${
            window.scrollY / 300
          })`
        }
        if (logoRef.current) {
          logoRef.current.style.opacity = `${window.scrollY / 300}`
        }
      }
      window.addEventListener("scroll", callBack)
      return () => {
        window.removeEventListener("scroll", callBack)
      }
    }
  }, [])

  return (
    <Box className={classes.container}>
      <Box className={classes.topLinkContainer}>
        {productPages.map(page => (
          <Box className={classes.topLink} key={"topmenu-" + page.link}>
            {page.link === "/" ? (
              <Link
                className={
                  NotContainWords(currentPathname, [
                    "pictures",
                    "services",
                    "productions",
                    "studios",
                  ])
                    ? classes.activeLink
                    : classes.link
                }
                to={page.link}
                key={page.link}
              >
                {page.name}
              </Link>
            ) : (
              <Link
                className={classes.link}
                activeClassName={classes.activeLink}
                partiallyActive={true}
                to={page.link}
                key={page.link}
              >
                {page.name}
              </Link>
            )}
          </Box>
        ))}
      </Box>
      <Box className={classes.desktopNavbar} ref={productsRef}>
        <DigicLink to="/" style={{ width: "80px", marginTop: "8px" }}>
          <img src={logo} alt="logo" className={classes.logo} />
        </DigicLink>
        <Box className={classes.iconWrapper} onClick={() => setMenuOpen(true)}>
          <MenuIcon />
        </Box>
      </Box>
      <Box className={classes.mobileNavbar}>
        <Box className={classes.menubar} ref={menubarRef}>
          <DigicLink to={to}>
            <img src={logo} alt="logo" className={classes.logoSubpage} />
          </DigicLink>
          <Box
            className={classes.iconWrapper}
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
