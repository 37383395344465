import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import OgImage from "../../images/og_image.jpg"

function SEO({ description, lang, meta, title = "DIGIC" }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={`${title} - We create digital magic`}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `viewport`,
          content: `minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no`,
        },
        {
          name: `description`,
          content:
            "DIGIC is an award-winning digital production studio focused on animation and asset creation for the video game industry.",
        },
        {
          property: `og:title`,
          content: `${title} - We create digital magic`,
        },
        {
          property: `og:image`,
          content: OgImage,
        },
        {
          property: `og:description`,
          content:
            "DIGIC is an award-winning digital production studio focused on animation and asset creation for the video game industry.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "DIGIC",
        },
        {
          name: `twitter:title`,
          content: `${title} - We create digital magic`,
        },
        {
          name: `twitter:description`,
          content:
            "DIGIC is an award-winning digital production studio focused on animation and asset creation for the video game industry.",
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
