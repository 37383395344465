import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  bar: {
    strokeDashoffset: 62.83,
    transition: "stroke-dasharray 0.7s ease-in-out",
    strokeWidth: "4px",
    stroke: "transparent",
  },
  cont: {
    display: "block",
    height: "88px",
    width: "88px",
    margin: "8px auto",
    borderRadius: "100%",
    position: "relative",
    transform: "scale(0.75)",
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.6)",
    },
  },
}))

export const CloseIcon = () => {
  const classes = useStyles()

  function hoverFunction(e) {
    e.target.style.strokeDasharray = 125.66 * 3
  }
  function leaveFunction(e) {
    e.target.style.strokeDasharray = 0
  }

  return (
    <div className={classes.cont} id="cont" data-pct="100">
      <svg
        id="svg"
        width="88"
        height="88"
        viewPort="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          fill="#F26522"
          x="44"
          y="0"
          transform="rotate(45 0 0)"
          width="40"
          height="3"
        ></rect>
        <rect
          fill="#F26522"
          x="-22"
          y="62"
          transform="rotate(-45 0 0)"
          width="40"
          height="3"
        ></rect>
        <circle
          className={classes.bar}
          id="bar"
          r="40"
          cx="44"
          cy="44"
          fill="transparent"
          onMouseOver={hoverFunction}
          onMouseLeave={leaveFunction}
          stroke-dasharray="0"
        ></circle>
      </svg>
    </div>
  )
}
