import {
  Box,
  Drawer,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import React from "react"
import { useState, useEffect } from "react"
import { useOpenHrDialog } from "../utils/hr-dialog"
import { CloseIcon } from "./close-icon"
import { MenuLink } from "./menu-link"
import Logo from "../../images/logo.svg"
import { Accordion } from "./accordion"
import { NotContainWords } from "../utils/NotContainWords"
import ScrollTo from "../utils/scroll-to"
import { useLocation } from "@reach/router"

const useStyles = makeStyles(theme => ({
  paper: { backgroundColor: "rgba(0,0,0,0)" },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "rgb(16,16,16,0.8)",
    minWidth: "400px",
    backdropFilter: "blur(10px)",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      minWidth: 0,
    },
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "52px",
    marginRight: "28px",
    marginLeft: "auto",
    border: "1px solid white",
    borderRadius: "100px",
    width: "56px",
    height: "56px",
    cursor: "pointer",
    borderWidth: "2px",
    borderColor: "#222",
    transition: "all 300ms",
    "&:hover": {
      borderColor: "#333",
      backgroundColor: "#0004",
    },
    [theme.breakpoints.down("sm")]: {
      width: "48px",
      height: "48px",
      borderWidth: "1px",
      marginTop: "40px",
      marginRight: "16px",
    },
  },
  dropdown: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
    borderRadius: "0px !important",
    borderBottom: "solid",
    borderBottomColor: "rgb(51,51,51,1)",
    "&:hover": {
      "& #logo": {
        opacity: 1.0,
      },
    },
    "&:before": {
      display: "none",
    },
  },
  dropdownItemsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "16px",
  },
  logo: {
    width: "100px",
    margin: "16px 0",
    opacity: 0.6,
    [theme.breakpoints.down("sm")]: {
      width: "75px",
    },
  },
  activeLlogo: {
    width: "100px",
    margin: "16px 0",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: "75px",
    },
  },
  summary: {
    marginRight: "20px",
  },
  link: {
    fontSize: "22px",
    fontWeight: 400,
    color: "#aaa",
    "&:hover": {
      color: "#fff",
    },
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    height: "100%",
    paddingTop: "6vh",
    overflowY: "scroll",
  },
}))

export const Menu = ({ isOpen, close }) => {
  const classes = useStyles()
  const openHrDialog = useOpenHrDialog()
  const location = useLocation()
  const currentPathname = location.pathname
  const [expanded, setExpanded] = useState(null)

  useEffect(() => {
    if (location.pathname.includes("/pictures")) {
      setExpanded("pictures")
    } else if (location.pathname.includes("/services")) {
      setExpanded("services")
    } else if (location.pathname.includes("/productions")) {
      setExpanded("productions")
    } else if (location.pathname.includes("/studios")) {
      setExpanded("studios")
    } else if (
      NotContainWords(location.pathname, [
        "pictures",
        "services",
        "productions",
        "studios",
      ])
    ) {
      setExpanded("main")
    } else {
      setExpanded(null)
    }
  }, [location])

  const handleAccordionClick = (accordion, event) => {
    if (
      expanded === accordion &&
      event.target.getAttribute("role") !== "button"
    ) {
      // if the clicked accordion is already expanded, collapse it
      setExpanded(null)
    } else {
      // otherwise, expand the clicked accordion
      setExpanded(accordion)
    }
  }

  const data = useStaticQuery(graphql`
    {
      strapi {
        picturesHomePage {
          DigicLogo {
            url
          }
        }
        servicesHomePage {
          ServicesHomeLogo {
            url
          }
        }
        studioPage {
          StudioLogo {
            url
          }
        }
        productionsHomePage {
          ProductionsHomeLogo {
            url
          }
        }
      }
    }
  `)

  const AboutItems = [
    { title: "About DIGIC", link: "/about" },
    { title: "20th Anniversary", link: "/anniversary" },
    { title: "CSR", link: "/csr" },
    { title: "Press", link: "/press" },
    { title: "FAQ", link: "/faq" },
  ]

  const CareerItems = [
    { title: "Join us", link: "/join-us" },
    { title: "Application tips", link: "/application-tips" },
    { title: "Job shorts", link: "/job-shorts" },
    { title: "Open positions", link: "/", onClick: openHrDialog },
  ]

  return (
    <Drawer
      open={isOpen}
      onClose={close}
      classes={{ paper: classes.paper }}
      transitionDuration={400}
      anchor="right"
    >
      <Box className={classes.container}>
        <Box className={classes.iconWrapper} onClick={close}>
          <CloseIcon />
        </Box>
        <Box className={classes.itemsWrapper}>
          {/*
          ------------
          HOME
          ------------
          */}
          <MuiAccordion
            expanded={expanded === "main"}
            onClick={event => handleAccordionClick("main", event)}
            className={classes.dropdown}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon color="primary" style={{ fontSize: 40 }} />
              }
              className={classes.summary}
            >
              <Box
                display="flex"
                justifyItems="center"
                mx="10px"
                maxWidth="400px"
              >
                <img
                  src={Logo}
                  alt="logo"
                  id="logo"
                  className={
                    NotContainWords(currentPathname, [
                      "pictures",
                      "services",
                      "productions",
                      "studios",
                    ])
                      ? classes.activeLlogo
                      : classes.logo
                  }
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.dropdownItemsContainer}>
              <MenuLink title="Home" link="/" />
              <Accordion title="About" items={AboutItems} />
              <Accordion title="Career" items={CareerItems} />
              <MenuLink title="News" link="/news" />
              <MenuLink title="Contact" link="/contact" />
            </AccordionDetails>
          </MuiAccordion>
          {/*
          ------------
          Pictures
          ------------
          */}
          <MuiAccordion
            expanded={expanded === "pictures"}
            onClick={event => handleAccordionClick("pictures", event)}
            className={classes.dropdown}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon color="primary" style={{ fontSize: 40 }} />
              }
              className={classes.summary}
            >
              <Box
                display="flex"
                justifyItems="center"
                mx="10px"
                maxWidth="400px"
              >
                <img
                  src={data.strapi.picturesHomePage.DigicLogo.url}
                  alt="logo"
                  id="logo"
                  className={
                    currentPathname.startsWith("/pictures")
                      ? classes.activeLlogo
                      : classes.logo
                  }
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.dropdownItemsContainer}>
              <MenuLink title="Home" link="/pictures" />
              <MenuLink
                title="Awards & Screenings"
                link="/pictures/awards-and-screenings"
              />
            </AccordionDetails>
          </MuiAccordion>
          {/*
          ------------
          Services
          ------------
          */}
          <MuiAccordion
            expanded={expanded === "services"}
            onClick={event => handleAccordionClick("services", event)}
            className={classes.dropdown}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon color="primary" style={{ fontSize: 40 }} />
              }
              className={classes.summary}
            >
              <Box
                display="flex"
                justifyItems="center"
                mx="10px"
                maxWidth="400px"
              >
                <img
                  src={data.strapi.servicesHomePage.ServicesHomeLogo.url}
                  alt="logo"
                  id="logo"
                  className={
                    currentPathname.startsWith("/services")
                      ? classes.activeLlogo
                      : classes.logo
                  }
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.dropdownItemsContainer}>
              <MenuLink title="Home" link="/services" />
              <MenuLink title="Motion capture" link="/services/mocap" />
              <MenuLink title="Photo scan" link="/services/photo-scan" />
            </AccordionDetails>
          </MuiAccordion>
          {/*
          ------------
          Studios
          ------------
          */}
          <MuiAccordion
            expanded={expanded === "studios"}
            onClick={event => handleAccordionClick("studios", event)}
            className={classes.dropdown}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon color="primary" style={{ fontSize: 40 }} />
              }
              className={classes.summary}
            >
              <Box
                display="flex"
                justifyItems="center"
                mx="10px"
                maxWidth="400px"
              >
                <img
                  src={data.strapi.studioPage.StudioLogo.url}
                  alt="logo"
                  id="logo"
                  className={
                    currentPathname.startsWith("/studios")
                      ? classes.activeLlogo
                      : classes.logo
                  }
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.dropdownItemsContainer}>
              {currentPathname.startsWith("/studios") ? (
                <>
                  <ScrollTo
                    to={"home"}
                    key={"home"}
                    onClick={close}
                    offset={-600}
                  >
                    <Box
                      mx={3}
                      my={1}
                      maxWidth="400px"
                      style={{ cursor: "pointer" }}
                    >
                      <Typography variant="h3" className={classes.link}>
                        Home
                      </Typography>
                    </Box>
                  </ScrollTo>
                  <ScrollTo
                    to={"services"}
                    key={"services"}
                    onClick={close}
                    offset={-120}
                  >
                    <Box
                      mx={3}
                      my={1}
                      maxWidth="400px"
                      style={{ cursor: "pointer" }}
                    >
                      <Typography variant="h3" className={classes.link}>
                        Our services
                      </Typography>
                    </Box>
                  </ScrollTo>
                </>
              ) : (
                <>
                  <MenuLink title="Home" link="/studios" />
                  <MenuLink title="Our services" link="/studios" />
                </>
              )}
            </AccordionDetails>
          </MuiAccordion>
          {/*
          ------------
          Productions
          ------------
          */}
          <MuiAccordion
            expanded={expanded === "productions"}
            onClick={event => handleAccordionClick("productions", event)}
            className={classes.dropdown}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon color="primary" style={{ fontSize: 40 }} />
              }
              className={classes.summary}
            >
              <Box
                display="flex"
                justifyItems="center"
                mx="10px"
                maxWidth="400px"
              >
                <img
                  src={data.strapi.productionsHomePage.ProductionsHomeLogo.url}
                  alt="logo"
                  className={
                    currentPathname.startsWith("/productions")
                      ? classes.activeLlogo
                      : classes.logo
                  }
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.dropdownItemsContainer}>
              <MenuLink title="Home" link="/productions" />
            </AccordionDetails>
          </MuiAccordion>
        </Box>
      </Box>
    </Drawer>
  )
}
