import { Link } from "gatsby"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  activeStyle: { fontWeight: "bold !important" },
})

export const DigicLink = ({ to, children, setActiveStyle, ...props }) => {
  const classes = useStyles()
  return (
    <Link
      to={to}
      activeClassName={setActiveStyle ? classes.activeStyle : "none"}
      style={{ textDecoration: "none", width: "100%" }}
      {...props}
    >
      {children}
    </Link>
  )
}
