import { Box } from "@material-ui/core"
import React from "react"
import Youtube from "../../images/social/Youtube.svg"
import Vimeo from "../../images/social/Vimeo.svg"
import LinkedIN from "../../images/social/LinkedIN.svg"
import Facebook from "../../images/social/Facebook.svg"
import Instagram from "../../images/social/Instagram.svg"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  socialIcon: {
    width: "40px",
    height: "40px",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },
}))

const socialLinks = [
  {
    name: "Youtube",
    icon: Youtube,
    link: "https://www.youtube.com/user/TheDigicPictures",
  },
  { name: "Vimeo", icon: Vimeo, link: "https://vimeo.com/digicpictures" },
  {
    name: "Linkedin",
    icon: LinkedIN,
    link: "https://www.linkedin.com/company/digic-pictures",
  },
  {
    name: "Facebook",
    icon: Facebook,
    link: "https://www.facebook.com/DigicPictures",
  },
  {
    name: "Instagram",
    icon: Instagram,
    link: "https://www.instagram.com/digicpictures",
  },
]

export const SocialLinks = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Box display="flex" justifyContent="center" gridGap={8} {...props}>
      {socialLinks.map(socialLink => (
        <a
          href={socialLink.link}
          target="_blank"
          rel="noreferrer"
          key={socialLink.name}
        >
          <img
            src={socialLink.icon}
            alt={socialLink.name}
            className={classes.socialIcon}
          />
        </a>
      ))}
    </Box>
  )
}
