import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { DigicLink } from "../digic-link"
import { useOpenHrDialog } from "../utils/hr-dialog"
import { Link } from "react-scroll"
import { SocialLinks } from "./social-links"
import szakreferens from "../../documents/DIGIC_ESZR_2023.pdf"
import whistleblowing from "../../documents/WhistleblowingSystem.pdf"

const aboutLinks = [
  { name: "About Digic", link: "/about" },
  { name: "CSR", link: "/csr" },
  { name: "Press", link: "/press" },
  { name: "FAQ", link: "/faq" },
]

const careerLinks = [
  { name: "Join us", link: "/join-us" },
  { name: "Job shorts", link: "/job-shorts" },
  { name: "Application tips", link: "/application-tips" },
]

const otherLinks = [
  { name: "News", link: "/news" },
  { name: "Contact", link: "/contact" },
]

const useStyles = makeStyles(theme => ({
  moreLineHeight: {
    lineHeight: "32px",
  },

  socialWrapper: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  pageLink: {
    margin: "4px 0",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },
  pageLinkBottom: {
    margin: "4px 0",
    opacity: 0.6,
    marginRight: "32px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginRight: "8px",
    },
    fontSize: "13px",
    "&:hover": {
      opacity: 1,
    },
  },
  container: {
    borderTop: "1px solid #343333",
    paddingTop: "96px",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "start",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "24px",
    },
    paddingTop: "96px",
    marginBottom: "4px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  logo: {
    width: "230px",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "180px",
      height: "65px",
    },
  },
}))

export const Footer = () => {
  const classes = useStyles()
  const openHrDialog = useOpenHrDialog()

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Box className={classes.container}>
      <Container>
        <Box className={classes.wrapper}>
          <Box display="flex" flexWrap="wrap">
            <Box display="flex" flexDirection="column" mr={8} mb={5}>
              <Typography variant="caption" className={classes.moreLineHeight}>
                About
              </Typography>
              {aboutLinks.map(page => (
                <DigicLink to={page.link} key={page.link}>
                  <Typography variant="h4" className={classes.pageLink}>
                    {page.name}
                  </Typography>
                </DigicLink>
              ))}
            </Box>
            <Box display="flex" flexDirection="column" mr={8} mb={5}>
              <Typography variant="caption" className={classes.moreLineHeight}>
                Career
              </Typography>
              {careerLinks.map(page => (
                <DigicLink to={page.link} key={page.link}>
                  <Typography variant="h4" className={classes.pageLink}>
                    {page.name}
                  </Typography>
                </DigicLink>
              ))}
              <Link to="" onClick={openHrDialog} style={{ cursor: "pointer" }}>
                <Typography variant="h4" className={classes.pageLink}>
                  open positions
                </Typography>
              </Link>
            </Box>
            <Box display="flex" flexDirection="column" mr={8} mb={5}>
              <Typography variant="caption" className={classes.moreLineHeight}>
                Other
              </Typography>
              {otherLinks.map(page => (
                <DigicLink to={page.link} key={page.link}>
                  <Typography variant="h4" className={classes.pageLink}>
                    {page.name}
                  </Typography>
                </DigicLink>
              ))}
            </Box>
          </Box>
          <Box className={classes.socialWrapper}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              className={classes.logo}
            />
            <SocialLinks mt={4} />
          </Box>
        </Box>
      </Container>
      <Container className={classes.bottomContainer}>
        <DigicLink to="/terms-and-conditions">
          <Typography variant="h4" className={classes.pageLinkBottom}>
            Terms and conditions
          </Typography>
        </DigicLink>
        <DigicLink to="/privacy-policy">
          <Typography variant="h4" className={classes.pageLinkBottom}>
            Privacy policy
          </Typography>
        </DigicLink>
        <DigicLink to="/gdpr">
          <Typography variant="h4" className={classes.pageLinkBottom}>
            GDPR
          </Typography>
        </DigicLink>
        <a
          href={szakreferens}
          key={szakreferens}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <Typography variant="h4" className={classes.pageLinkBottom}>
            ESZR-REPORT
          </Typography>
        </a>
        <a
          href={whistleblowing}
          key={whistleblowing}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", width: "100%" }}
        >
          <Typography variant="h4" className={classes.pageLinkBottom}>
            Whistleblowing Reporting System
          </Typography>
        </a>
      </Container>
    </Box>
  )
}
