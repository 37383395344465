import React from "react"
import {
  Box,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { MenuLink } from "./menu-link"

const useStyles = makeStyles(theme => ({
  dropdown: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
    "&:hover": {
      "& #accordion-title": {
        color: "#fff",
      },
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  dropdownItemsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  textStyle: {
    fontSize: "22px",
    fontWeight: 400,
    color: "#aaa",
    "&:hover": {
      color: "#fff",
    },
  },
}))

export const Accordion = ({ title, items }) => {
  const classes = useStyles()

  return (
    <MuiAccordion
      className={classes.dropdown}
      onClick={event => {
        event.stopPropagation()
      }}
    >
      <AccordionSummary
        expandIcon={
          <ArrowDropDownIcon color="primary" style={{ fontSize: 40 }} />
        }
        style={{ width: "100%" }}
      >
        <Box mx="10px" maxWidth="400px">
          <Typography
            variant="h2"
            id="accordion-title"
            className={classes.textStyle}
          >
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.dropdownItemsContainer}>
        {items.map(item => (
          <MenuLink
            title={item.title}
            link={item.link}
            onClick={item.onClick}
          />
        ))}
      </AccordionDetails>
    </MuiAccordion>
  )
}
